const faqs = [
  {
    question: "Visa de trabajo por cuenta ajena:",
    answer: (
      <div>
        <p>Si tienes una oferta de trabajo de una empresa española, puedes solicitar una visa de trabajo por cuenta ajena. Esta visa te permite trabajar para esa empresa durante un período de tiempo determinado. </p>
      </div>
    ),
  },
  {
    question: "Visa de trabajo por cuenta propia:",
    answer: (
      <div>
        <p>Si deseas iniciar tu propio negocio en España, puedes solicitar una visa de trabajo por cuenta propia. Esta visa te permite establecerte como autónomo y trabajar en tu propio negocio.
 </p> 

      </div>
    ),
  },
  {
    question: "Visa de trabajo para profesionales altamente cualificados:",
    answer: (
      <div>
        <p>
        Si tienes habilidades especiales o una formación universitaria avanzada, puedes solicitar una visa de trabajo para profesionales altamente cualificados. Esta visa te permite trabajar en un sector específico en España. <br/> <br/> Una vez que sepas qué tipo de visa de trabajo necesitás, debes reunir la documentación necesaria y presentarla ante la Embajada o Consulado español en Argentina. 
        </p> 
      </div>
    ),
  },
  {
    question: "Requisitos para solicitar la visa de trabajo en España para Argentinos",
    answer: (
      <div>
        <p>
        Los requisitos pueden variar según el tipo de visa, pero en general necesitarás lo siguiente: 
        </p> <br/>
        <p>- Pasaporte válido y en buen estado.</p>
        <p>- Formulario de solicitud de visa de trabajo debidamente completado y firmado.</p>
        <p>- Fotografías recientes (generalmente dos).</p>
        <p>- Certificado de antecedentes penales emitido por las autoridades argentinas.</p>
        <p>- Certificado médico que acredite que no padeces enfermedades que puedan afectar a la salud pública.</p>
        <p>- Documentación que acredite que tienes un contrato de trabajo con una empresa española (en el caso de la visa de trabajo por cuenta ajena) o que dispones de los recursos económicos necesarios para establecerte como autónomo (en el caso de la visa de trabajo por cuenta propia).</p>
        <p>- Documentación que acredite que tienes la formación o habilidades especiales requeridas para la visa de trabajo para profesionales altamente cualificados.</p> <br/>
        <p>Una vez que hayas reunido toda la documentación, debes pedir una cita en la Embajada o Consulado español en Argentina para presentar tu solicitud de visa de trabajo. Es importante que solicites la cita con suficiente antelación antes de tu fecha de viaje, ya que puede haber una lista de espera. <br/> <br/>Durante la entrevista en la Embajada o Consulado, te preguntarán sobre tus planes en España y tu situación laboral. Una vez que hayas presentado tu solicitud de visa de trabajo, deberás esperar a que te informen sobre el resultado. Si tu solicitud es aprobada, te darán la visa de trabajo en tu pasaporte y podrás viajar a España. </p>
      </div>
    ),
  },
];

export default faqs;
